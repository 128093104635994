    var Ziggy = {
        namedRoutes: {"nova.login":{"uri":"nova\/login","methods":["POST"],"domain":null},"nova.logout":{"uri":"nova\/logout","methods":["GET","HEAD"],"domain":null},"nova.password.request":{"uri":"nova\/password\/reset","methods":["GET","HEAD"],"domain":null},"nova.password.email":{"uri":"nova\/password\/email","methods":["POST"],"domain":null},"nova.password.reset":{"uri":"nova\/password\/reset\/{token}","methods":["GET","HEAD"],"domain":null},"index":{"uri":"\/","methods":["GET","HEAD"],"domain":null},"login":{"uri":"login","methods":["GET","HEAD"],"domain":null},"login.attempt":{"uri":"login","methods":["POST"],"domain":null},"register":{"uri":"register","methods":["GET","HEAD"],"domain":null},"register.attempt":{"uri":"register","methods":["POST"],"domain":null},"logout":{"uri":"logout","methods":["GET","HEAD"],"domain":null},"intro":{"uri":"intro","methods":["GET","HEAD"],"domain":null},"intro.step2":{"uri":"intro\/2","methods":["GET","HEAD"],"domain":null},"intro.step4":{"uri":"intro\/4","methods":["GET","HEAD"],"domain":null},"character.create":{"uri":"character\/create","methods":["POST"],"domain":null},"character.show":{"uri":"character\/{character}","methods":["GET","HEAD"],"domain":null},"home":{"uri":"home","methods":["GET","HEAD"],"domain":null},"nova.index":{"uri":"nova","methods":["GET","HEAD"],"domain":null}},
        baseUrl: 'https://xivoc.com/',
        baseProtocol: 'https',
        baseDomain: 'xivoc.com',
        basePort: false,
        defaultParameters: []
    };

    if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
        for (var name in window.Ziggy.namedRoutes) {
            Ziggy.namedRoutes[name] = window.Ziggy.namedRoutes[name];
        }
    }

    export {
        Ziggy
    }
